import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useReducer
} from "react"
import PropTypes from "prop-types"
import createAuth0Client from "@auth0/auth0-spa-js"
import AuthReducer from "./AuthReducer"
import * as type from "./AuthTypes"
import { savePreferences } from "../helpers/userHelpers"
import { navigate } from "gatsby"
import { transformDatoIdIntoNumbers } from "./transformDatoIdIntoNumbers"

const preferences = `${process.env.GATSBY_AUTH0_NAMESPACE}preferences`
const permissions = `${process.env.GATSBY_AUTH0_NAMESPACE}permissions`
const country = `${process.env.GATSBY_AUTH0_NAMESPACE}country`
const countryCode = `${process.env.GATSBY_AUTH0_NAMESPACE}code`
const city = `${process.env.GATSBY_AUTH0_NAMESPACE}city`

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

const defaultContext = {
  isAuthenticated: false,
  user: null,
  loading: false,
  popupOpen: false,
  favouriteWorkouts: [],
  completedWorkouts: [],
  favouriteRecipes: [],
  favouriteVlogs: [],
  subscriptions: [],
  hasActiveSubscriptions: null,
  hasCancelledPlan: null,
  hasPausedPlan: null,
  region: {
    country: null,
    code: null,
    city: null
  },
  loginWithPopup: () => {},
  handleRedirectCallback: () => {},
  getIdTokenClaims: () => {},
  loginWithRedirect: () => {},
  getTokenSilently: () => {},
  getTokenWithPopup: () => {},
  logout: () => {}
}

export const Auth0Context = createContext(defaultContext)
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [state, dispatch] = useReducer(AuthReducer, defaultContext)
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState({})
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const authenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(authenticated)

      if (authenticated) {
        const username = await auth0FromHook.getUser()
        setUser(username)

        if (username[permissions].subscriptions.length === 0) {
          navigate("/subscribe")
        }

        if (username[permissions].subscriptions.length >= 1) {
          dispatch({
            type: type.GET_USER_SUBSCRIPTIONS,
            payload: username[permissions].subscriptions
          })
        }

        if (username[preferences]?.workouts?.length > 0) {
          const workouts = username[preferences]?.workouts
          // const formattedIds = transformDatoIdIntoNumbers(workouts)
          dispatch({
            type: type.GET_FAVOURITE_WORKOUT,
            payload: workouts
          })
        }
        //---------------------//

        if (username[preferences]?.completed?.length > 0) {
          const completed = username[preferences]?.completed
          // const formattedIds = transformDatoIdIntoNumbers(completed)
          dispatch({
            type: type.GET_COMPLETED_WORKOUT,
            payload: completed
          })
        }

        //---------------------//

        if (username[preferences]?.recipes?.length > 0) {
          const recipes = username[preferences]?.recipes
          // const formattedIds = transformDatoIdIntoNumbers(recipes)
          dispatch({
            type: type.GET_FAVOURITE_RECIPE,
            payload: recipes
          })
        }
        if (username[preferences]?.vlogs?.length > 0) {
          const vlogs = username[preferences]?.vlogs
          // const formattedIds = transformDatoIdIntoNumbers(vlogs)
          dispatch({
            type: type.GET_FAVOURITE_LATEST_VLOG,
            payload: vlogs
          })
        }

        if (username[country]) {
          dispatch({
            type: type.GET_USER_REGION,
            country: username[country],
            code: username[countryCode],
            city: username[city]
          })
        }
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //active user with only cancelled plans ACTION: redirect to profile page
    if (user && state.hasCancelledPlan && !state.hasActiveSubscriptions) {
      navigate("/account/profile")
    }
  }, [state.hasCancelledPlan, state.hasActiveSubscriptions])

  // uncomment to update favourites on Auth0
  useEffect(() => {
    if (user) {
      savePreferences(user.sub, state)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    /*eslint-env es6*/
  }, [state])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const username = await auth0Client.getUser()
    setUser(username)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const username = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(username)
  }

  const addFavouriteWorkouts = workout => {
    dispatch({ type: type.ADD_FAVOURITE_WORKOUT, payload: workout })
  }

  const addCompletedWorkouts = workout => {
    dispatch({ type: type.ADD_COMPLETED_WORKOUT, payload: workout })
  }

  const addFavouriteRecipes = recipe => {
    dispatch({ type: type.ADD_FAVOURITE_RECIPE, payload: recipe })
  }
  const addFavouriteVlogs = vlog => {
    dispatch({ type: type.ADD_FAVOURITE_VLOGS, payload: vlog })
  }
  const removeFavouriteWorkouts = workout => {
    dispatch({ type: type.REMOVE_FAVOURITE_WORKOUT, payload: workout })
  }
  const removeCompletedWorkouts = workout => {
    dispatch({ type: type.REMOVE_COMPLETED_WORKOUT, payload: workout })
  }
  const removeFavouriteRecipes = recipe => {
    dispatch({ type: type.REMOVE_FAVOURITE_RECIPE, payload: recipe })
  }
  const removeFavouriteVlogs = vlog => {
    dispatch({ type: type.REMOVE_FAVOURITE_VLOG, payload: vlog })
  }
  return (
    <Auth0Context.Provider
      value={{
        favouriteWorkouts: state.favouriteWorkouts,
        completedWorkouts: state.completedWorkouts,
        favouriteRecipes: state.favouriteRecipes,
        favouriteVlogs: state.favouriteVlogs,
        subscriptions: state.subscriptions,
        hasCancelledPlan: state.hasCancelledPlan,
        hasPausedPlan: state.hasPausedPlan,
        hasActiveSubscriptions: state.hasActiveSubscriptions,
        region: state.region,
        user,
        addFavouriteWorkouts,
        addCompletedWorkouts,
        addFavouriteRecipes,
        addFavouriteVlogs,
        removeFavouriteWorkouts,
        removeCompletedWorkouts,
        removeFavouriteRecipes,
        removeFavouriteVlogs,
        isAuthenticated,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

Auth0Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onRedirectCallback: PropTypes.func.isRequired
}
