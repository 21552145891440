import * as type from "./FavouriteTypes"

const FavouriteReducer = (state, action) => {
  switch (action.type) {
    case type.GET_FAVOURITE_WORKOUT:
      return {
        ...state,
        workouts: action.payload,
        hasFavourites: action.hasFavourites
      }
      break

    case type.GET_FAVOURITE_RECIPE:
      return { ...state, recipes: action.payload }
      break
    case type.GET_FAVOURITE_VLOG:
      return { ...state, vlogs: action.payload }
      break
    case type.ADD_FAVOURITE_WORKOUT:
      return { ...state, workouts: [...state.workouts, action.payload] }
      break
    case type.REMOVE_FAVOURITE_WORKOUT:
      return {
        ...state.payload,
        workouts: [
          ...state.workouts.filter(
            workout => workout.originalId !== action.payload.originalId
          )
        ]
      }
      break
    case type.REMOVE_WORKOUT_DATA:
      return {
        ...state,
        workouts: state.workouts.filter(
          workout => workout.originalId !== action.payload
        )
      }
      break
    case type.REMOVE_RECIPE_DATA:
      return {
        ...state,
        recipes: state.recipes.filter(
          recipe => recipe.originalId !== action.payload
        )
      }
      break
    case type.REMOVE_VLOG_DATA:
      return {
        ...state,
        vlogs: state.vlogs.filter(vlog => vlog.originalId !== action.payload)
      }
      break
    default:
      return ""
      break
  }
}

export default FavouriteReducer
