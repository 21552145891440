import * as type from "./AuthTypes"

const AuthReducer = (state, action) => {
  switch (action.type) {
    case type.GET_AUTHENTICATED_USER:
      return { ...state, authenticatedUser: action.payload }
    case type.GET_FAVOURITE_WORKOUT:
      return { ...state, favouriteWorkouts: action.payload }
    case type.GET_COMPLETED_WORKOUT:
      return { ...state, completedWorkouts: action.payload }
    case type.GET_FAVOURITE_RECIPE:
      return { ...state, favouriteRecipes: action.payload }
    case type.GET_FAVOURITE_LATEST_VLOG:
      return { ...state, favouriteVlogs: action.payload }
    case type.GET_USER_SUBSCRIPTIONS:
      const activeSubscriptions = action.payload.filter(
        plan => plan.status !== "cancelled" && plan.status !== "paused"
      )

      return {
        ...state,
        subscriptions: action.payload.map(subscription => ({
          ...subscription, // Ensure you are spreading all properties from the subscription object
          items: subscription.subscription_items || [] // Ensure subscription_items are included
        })),
        hasCancelledPlan: action.payload.some(
          plan => plan.status === "cancelled" || plan.status === "paused"
        ),
        hasPausedPlan: action.payload.some(plan => plan.status === "paused"),
        hasActiveSubscriptions: activeSubscriptions.length > 0
      }
    case type.GET_USER_REGION:
      return {
        ...state,
        region: {
          country: action.country,
          code: action.code,
          city: action.city
        }
      }
    case type.ADD_FAVOURITE_WORKOUT:
      return {
        ...state,
        favouriteWorkouts: [...state.favouriteWorkouts, action.payload]
      }

    //---------------------//

    case type.ADD_COMPLETED_WORKOUT:
      return {
        ...state,
        completedWorkouts: [...state.completedWorkouts, action.payload]
      }

    //---------------------//

    case type.ADD_FAVOURITE_RECIPE:
      return {
        ...state,
        favouriteRecipes: [...state.favouriteRecipes, action.payload]
      }
    case type.ADD_FAVOURITE_VLOGS:
      return {
        ...state,
        favouriteVlogs: [...state.favouriteVlogs, action.payload]
      }
    case type.GET_LOCALSTORAGE_FAVOURITE_WORKOUT:
      return { ...state, favouriteWorkouts: action.payload }
    case type.REMOVE_FAVOURITE_WORKOUT:
      return {
        ...state,
        favouriteWorkouts: state.favouriteWorkouts.filter(
          workout => workout !== action.payload
        )
      }
    case type.REMOVE_COMPLETED_WORKOUT:
      return {
        ...state,
        completedWorkouts: state.completedWorkouts.filter(
          workout => workout !== action.payload
        )
      }
    case type.REMOVE_FAVOURITE_RECIPE:
      return {
        ...state,
        favouriteRecipes: state.favouriteRecipes.filter(
          recipe => recipe !== action.payload
        )
      }
    case type.REMOVE_FAVOURITE_VLOG:
      return {
        ...state,
        favouriteVlogs: state.favouriteVlogs.filter(
          vlog => vlog !== action.payload
        )
      }
    default:
      return null
  }
}

export default AuthReducer
