// import { useState } from "react"
import { navigate } from "gatsby"
// import { groups, rules } from "@config/access"
// const config = require("@config/website")
// const { sections } = config[process.env.GATSBY_LANGUAGE]

// function useAccess() {
// const [acl, setAcl] = useState({
//   path: "/",
//   group: "public",
//   hasAccess: true,
//   redirectUrl: "/"
// })
// const { profile, isLoading, isLoggedIn } = useAuth()

// useEffect(() => {
//   let hasAccess = false
//   let redirectUrl = "/"

//   if (!isLoading) {
//     const pathname = window.location.pathname

//     const curGroup = groups.find(group => {
//       for (let i = 0; i < rules[group].length; i++) {
//         let result = rules[group][i].test(pathname)

//         if (result) {
//           return true
//           break
//         }
//       }

//       return false
//     })

//     switch (curGroup) {
//       case "public":
//         hasAccess = true
//         break
//       case "private":
//         hasAccess = isLoggedIn
//         break
//       case "paidOnly":
//       default:
//         hasAccess = isLoggedIn && profile && profile.isSubscribed

//         redirectUrl =
//           profile && profile.isNew
//             ? sections.subscribe.base
//             : sections.profile.base
//     }

//     setAcl({
//       path: pathname,
//       group: curGroup,
//       hasAccess,
//       redirectUrl
//     })
//   }
//   return () => {}
// }, [isLoggedIn, isLoading, profile])

// return acl
// }

const checkoutUser = (uid, plan_id, redirect_url, cancel_url) => {
  fetch(
    `/.netlify/functions/checkoutUser?uid=${uid}&plan_id=${plan_id}&redirect_url=${redirect_url}&cancel_url=${cancel_url}`
  )
    .then(response => response.json())
    .then(function(response) {
      navigate(response.url)
    })
    .catch(function(error) {
      console.log(error)
    })
}

const getPortalSession = (uid, redirect_url) => {
  fetch(`/.netlify/functions/myPortal?uid=${uid}&redirect_url=${redirect_url}`)
    .then(response => response.json())
    .then(function(response) {
      navigate(response.access_url)
    })
    .catch(function(error) {
      console.log(error)
    })
}

const savePreferences = (sub, newValue) => {
  let metadata = {
    workouts:
      newValue.favouriteWorkouts.length === 0
        ? undefined
        : newValue.favouriteWorkouts,
    completed:
      newValue.completedWorkouts.length === 0
        ? undefined
        : newValue.completedWorkouts,
    recipes:
      newValue.favouriteRecipes.length === 0
        ? undefined
        : newValue.favouriteRecipes,
    vlogs:
      newValue.favouriteVlogs.length === 0 ? undefined : newValue.favouriteVlogs
  }

  fetch(`/.netlify/functions/updateUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      user_id: sub,
      metadata: metadata
    })
  })
    .then(response => response.json())
    .then(function(response) {
      return response
    })
    .catch(function(error) {
      console.log(error)
    })
}

// const updateFavourites = (
//   ids,
//   profileState,
//   setProfileState,
//   type = "workouts"
// ) => {
//   const newState = { ...profileState }

//   newState.preferences[type] = ids

//   updatePreferences(newState, setProfileState)
// }

// const addFavourite = (id, profileState, setProfileState, type = "workouts") => {
//   const newState = { ...profileState }
//   let favourites = newState.preferences[type]
//   const index = favourites.indexOf(id)

//   if (index === -1) {
//     favourites = [id, ...favourites]
//   }

//   newState.preferences[type] = favourites

//   updatePreferences(newState, setProfileState)
// }

// const removeFavourite = (
//   id,
//   profileState,
//   setProfileState,
//   type = "workouts"
// ) => {
//   const newState = { ...profileState }
//   const favourites = newState.preferences[type]
//   const index = favourites.indexOf(id)

//   if (index > -1) {
//     favourites.splice(index, 1)
//   }

//   newState.preferences[type] = favourites

//   updatePreferences(newState, setProfileState)
// }

// const updatePreferences = async (newState, setProfileState) => {
//   setProfileState(newState)

//   try {
//     await savePreferences(newState.sub, newState.preferences)
//   } catch (error) {
//     console.log("Save profile failed", error)
//   }
// }

const getNodes = (ids = [], nodes = []) => {
  // No nodes to return
  if (ids.length < 0 || nodes.length < 0) return []

  // Search Nodes for ids
  const results = ids.map(key => nodes.find(({ id }) => id === key))
  return results.filter(node => node !== undefined)
}

// const getNodesAndPruneIds = (idArray = [], nodeArray = []) => {
//   const filteredNodes = getNodes(idArray, nodeArray)
//   const filteredIds = filteredNodes.map(({ id }) => id)

//   return {
//     nodes: filteredNodes,
//     ids: filteredIds
//   }
// }

const getNode = (id = "", nodes = []) => nodes.find(node => node.id === id)

export {
  checkoutUser,
  getPortalSession,
  savePreferences,
  // removeFavourite,
  // updateFavourites,
  getNodes,
  // getNodesAndPruneIds,
  getNode
  // addFavourite,
  // useAccess
}
