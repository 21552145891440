export const GET_AUTHENTICATED_USER = "GET_AUTHENTICATED_USER"
export const GET_USER_REGION = "GET_USER_REGION"
export const GET_USER_SUBSCRIPTIONS = "GET_USER_SUBSCRIPTIONS"
export const GET_FAVOURITE_WORKOUT = "GET_FAVOURITE_WORKOUT"
export const GET_COMPLETED_WORKOUT = "GET_COMPLETED_WORKOUT"
export const GET_FAVOURITE_RECIPE = "GET_FAVOURITE_RECIPE"
export const GET_FAVOURITE_LATEST_VLOG = "GET_FAVOURITE_LATEST_VLOG"
export const ADD_FAVOURITE_WORKOUT = "ADD_FAVOURITE_WORKOUT"
export const ADD_COMPLETED_WORKOUT = "ADD_COMPLETED_WORKOUT"
export const ADD_FAVOURITE_RECIPE = "ADD_FAVOURITE_RECIPE"
export const ADD_FAVOURITE_VLOGS = "ADD_FAVOURITE_VLOGS"
export const REMOVE_FAVOURITE_WORKOUT = "REMOVE_FAVOURITE_WORKOUT"
export const REMOVE_COMPLETED_WORKOUT = "REMOVE_COMPLETED_WORKOUT"
export const REMOVE_FAVOURITE_RECIPE = "REMOVE_FAVOURITE_RECIPE"
export const REMOVE_FAVOURITE_VLOG = "REMOVE_FAVOURITE_VLOG"
export const ADD_FAVOURITES_AUTH0 = "ADD_FAVOURITES_AUTH0"
export const GET_LOCALSTORAGE_FAVOURITE_WORKOUT =
  "GET_LOCALSTORAGE_FAVOURITE_WORKOUT"
