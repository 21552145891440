import * as type from "./ChallengeType"

const ChallengeReducer = (state, action) => {
  switch (action.type) {
    case type.GET_CHALLENGE_LISTS:
      return {
        ...state,
        autoPilotLists: action.list,
        hasBeenChecked: action.hasBeenChecked,
        loadingChallenge: action.loadingChallenge
      }
    case type.SET_LOADING:
      return { ...state, loadingChallenge: action.payload }
    case type.REMOVE_CHALLENGE_FROM_LIST:
      return {
        ...state,
        isRegistering: action.isRegistering,
        autoPilotLists: state?.autoPilotLists.filter(
          challenge => challenge !== action.payload
        )
      }
    case type.ADD_CHALLENGE_TO_LIST:
      return {
        ...state,
        isRegistering: action.isRegistering,
        autoPilotLists: [...state.autoPilotLists, action.payload]
      }
    case type.IS_REGISTERING:
      return { ...state, isRegistering: action.payload, error: action.error }
    case type.ERROR:
      return {
        ...state,
        isRegistering: action.isRegistering,
        error: action.error
      }

    default:
      return undefined
      break
  }
}

export default ChallengeReducer
