import React, { useReducer, createContext, useContext } from "react"
import FavouriteReducer from "./FavouriteReducer"
import * as type from "./FavouriteTypes"

const initialState = {
  workouts: [],
  recipes: [],
  vlogs: [],
  hasFavourites: false,
  getWorkoutsData: () => {},
  removeWorkoutData: () => {},
  removeRecipesData: () => {},
  removeVlogsData: () => {},
  getRecipeData: () => {},
  getVlogData: () => {},
  addWorkout: () => {},
  removeWorkout: () => {}
}

export const FavouriteContext = createContext(initialState)
export const useFavourite = () => useContext(FavouriteContext)

const FavouriteState = ({ children }) => {
  const [state, dispatch] = useReducer(FavouriteReducer, initialState)

  const getWorkoutsData = data => {
    dispatch({
      type: type.GET_FAVOURITE_WORKOUT,
      payload: data,
      hasFavourites: true
    })
  }
  const getRecipeData = data => {
    dispatch({ type: type.GET_FAVOURITE_RECIPE, payload: data })
  }
  const getVlogData = data => {
    dispatch({ type: type.GET_FAVOURITE_VLOG, payload: data })
  }
  const removeWorkoutData = workoutId => {
    dispatch({ type: type.REMOVE_WORKOUT_DATA, payload: workoutId })
  }
  const removeRecipesData = recipeId => {
    dispatch({ type: type.REMOVE_RECIPE_DATA, payload: recipeId })
  }
  const removeVlogsData = vlogId => {
    dispatch({ type: type.REMOVE_VLOG_DATA, payload: vlogId })
  }

  const addWorkout = workoutId => {
    dispatch({ type: type.ADD_FAVOURITE_WORKOUT, payload: workoutId })
  }
  const removeWorkout = workoutId => {
    dispatch({ type: type.REMOVE_FAVOURITE_WORKOUT, payload: workoutId })
  }

  return (
    <FavouriteContext.Provider
      value={{
        workouts: state.workouts,
        recipes: state.recipes,
        vlogs: state.vlogs,
        hasFavourites: state.hasFavourites,
        getWorkoutsData,
        getRecipeData,
        getVlogData,
        removeWorkoutData,
        removeRecipesData,
        removeVlogsData,
        addWorkout,
        removeWorkout
      }}
    >
      {children}
    </FavouriteContext.Provider>
  )
}

export default FavouriteState
