import React from "react"
import { ThemeProvider } from "@context/themeContext"
import vhCheck from "vh-check"
import "firebase/database"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import Meta from "@elements/Meta"
import { CastProvider } from "react-cast-sender"
import { Auth0Provider } from "./src/utils/auth"
import FavouriteState from "./src/context/FavouritesContext/FavouriteState"
import ChallengeState from "./src/context/ChallengeContext/ChallengeState"
import { navigate } from "@reach/router"
import "./src/assets/scss/styles.global.scss"
const pjson = require("./package.json")

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const Auth0Domain = process.env.GATSBY_AUTH0_CUSTOM_DOMAIN
const Auth0ClientID = process.env.GATSBY_AUTH0_CLIENTID
const Auth0RedirectURI = process.env.GATSBY_AUTH0_CALLBACK

export const wrapRootElement = ({ element }) => {
  vhCheck()
  console.info(`Dedikate PWA. Version: ${pjson.version}`)

  return (
    <Auth0Provider
      domain={Auth0Domain}
      client_id={Auth0ClientID}
      redirect_uri={Auth0RedirectURI}
      onRedirectCallback={onRedirectCallback}
    >
      <ChallengeState>
        <FavouriteState>
          <ThemeProvider>
            <Meta />
            <CastProvider
              receiverApplicationId="269EF9B8"
              resumeSavedSession={true}
            >
              {element}
            </CastProvider>
          </ThemeProvider>
        </FavouriteState>
      </ChallengeState>
    </Auth0Provider>
  )
}
